import React from "react"
import Layout from "../components/layout"

import { graphql, useStaticQuery } from "gatsby"
import "../styles/main.scss"

import suli_istvan from "../images/suli_istvan.jpg"

const RolamPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(rolam)/" } }
        sort: { order: DESC, fields: [frontmatter___id] }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              id
            }
            html
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h1 className="text-center pt-6 pb-5 ">Rólam</h1>

            <img
              src={suli_istvan}
              alt="Süli István"
              className="img-fluid float-left pr-4 pb-3"
            ></img>

            {data.allMarkdownRemark.edges.map(edge => {
              return (
                <div className="post " key={edge.node.id}>
                  {/* <h2>{edge.node.frontmatter.title}</h2> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: edge.node.html,
                    }}
                  ></div>
                </div>
              )
            })}
          </div>
          <div className="col-md-2"></div>
        </div>

        <div className="col-md-2"></div>
      </div>
    </Layout>
  )
}

export default RolamPage
